$(function() {
    const $btnPay = $('#submit-payment-button');
    let busy = false;

    if(!$btnPay.length || typeof Judopay === 'undefined') return;

    Judopay.judo.createCardDetails('judopayFormCard', {
        iframe: Judopay.iframe
    });

    $btnPay.on('click', function() {
        if(busy) return;

        $btnPay.prop('disabled', 'disabled');
        busy = true;

        $.get('/shop/checkout/judopay/init/?method=form')
            .then(function (res) {
                return Judopay.judo.invokePayment(res.data.ref, res.data.config)
                    .then(function(res) {
                        return $.post(Judopay.urls.payment, res);
                    });
            })
            .done(function(res) {
                window.location.assign(res.redirect);
            })
            .fail(function (err) {
                alert(err.message || "Your transaction was not successful please try again");
                console.error(err);
            })
            .always(function() {
                $btnPay.prop('disabled', false);
                busy = false;
            });
    });
});

$(function() {

    if(typeof Judopay !== 'undefined') {
        Judopay.loadLibrary = function () {
            if(this.librayLoaded) return;

            this.librayLoaded = true;

            $('<div id="judopayIframe" style="position:absolute;width:0;height:0;border:0;"></div>').prependTo(document.body);
            this.judo.createCardDetails('judopayIframe');

        }
    }


});

Vue.component('judopay-apay', {
    props: ['merchantId'],
    data: function() {
        return {
            ready: false,
            button: null,
            session: null,
        }
    },
    mounted: function() {
        const vm = this;

        if(window.apayLoaded) {
            vm.initPayBtn();
        } else {
            $.getScript('https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js')
                .done(function() {
                    window.apayLoaded = true;

                    vm.initPayBtn();
                })
                .fail(function() {
                    window.apayLoaded = false;
                });
        }
    },
    methods: {
        initPayBtn: function() {
            if (!window.ApplePaySession || !ApplePaySession.canMakePayments()) return;

            const vm = this;

            $.get('/shop/checkout/judopay/init/?method=apay')
                .then(function(res) {
                    vm.session = res.data;

                    vm.$nextTick(function() {
                        Judopay.loadLibrary();
                        $('#judopayAPayBtn').on('click', function (e) {
                            vm.handlePay(e);
                        });
                    });
                });
        },
        handlePay: function(e) {
            const vm = this;

            if(!vm.session.ref) return;

            e.preventDefault();

            const session = new ApplePaySession(3, vm.session.config.paymentRequest);

            session.onvalidatemerchant = function(e) {
                Judopay.judo.postMessage({
                    params: {
                        apiToken: Judopay.token,
                        sandbox: Judopay.testmode,
                        judoId: Judopay.merchantID,
                        initiativeContext: window.location.hostname,
                        paymentSession: vm.session.ref,
                        validationUrl: e.validationURL,
                    },
                    messageType: 'CREATE_APPLE_PAY_SESSION',
                })
                    .then(function(res) {
                        session.completeMerchantValidation(res);
                    })
                    .catch(err => alert(err));
            };
            session.onshippingcontactselected = function(e) {
                $.post('/shop/checkout/judopay/apay/update/', {
                    shippingContact: e.shippingContact,
                })
                    .done(function(res) {
                        session.completeShippingContactSelection(res);
                    });
            }
            session.onshippingmethodselected = function(e) {
                $.post('/shop/checkout/judopay/apay/update/', {
                    shippingMethod: e.shippingMethod,
                })
                    .done(function(res) {
                        session.completeShippingMethodSelection(res);
                    });
            }
            session.onpaymentauthorized = function(e) {
                $.post('/shop/checkout/judopay/apay/authorize/', e.payment)
                    .done(function (res) {
                        session.completePayment({
                            status: session.STATUS_SUCCESS,
                        });

                        window.location.assign(res.redirect);
                    })
                    .fail(function(xhr, _, err) {
                        if(xhr && xhr.responseText) {
                            err = JSON.parse(xhr.responseText);
                        }

                        session.completePayment({
                            status: session.STATUS_FAILURE,
                            errors: err.errors ? err.errors.map(o => new window.ApplePayError(o.code, o.field, o.message)) : [
                                new window.ApplePayError('unknown', 'name', err.message || 'Something went wrong, please try again later!!')
                            ],
                        });
                    });
            }

            session.begin();
        },
    }
});

Vue.component('judopay-gpay', {
    data: function() {
        return {
            session: null,
            client: null,
        };
    },
    mounted: function() {
        const vm = this;

        if(!window.google) {
            $.getScript('https://pay.google.com/gp/p/js/pay.js')
                .done(function() {
                    vm.initPayBtn();
                });
        } else {
            vm.initPayBtn();
        }

    },
    methods: {
        initPayBtn: function() {
            const vm = this;
            const container = this.$refs.container;

            if(!container) return;

            $.get('/shop/checkout/judopay/init/?method=gpay')
                .then(function(res) {
                    vm.client = new google.payments.api.PaymentsClient({
                        environment: res.data.environment,
                        paymentDataCallbacks: {
                            onPaymentDataChanged: function (e) {
                                return $.post('/shop/checkout/judopay/gpay/update/', e);
                            },
                            onPaymentAuthorized: function (e) {
                                return $.post('/shop/checkout/judopay/gpay/authorize/', e)
                                    .then(function(res) {
                                        vm.payload = res.payload;

                                        return res;
                                    });
                            },
                        },
                    });

                    vm.client.isReadyToPay(res.data.config).
                    then(function(response) {
                        if (response.result) {
                            const button = vm.client.createButton({
                                buttonColor: 'black',
                                buttonLocale: 'en',
                                buttonSizeMode: 'fill',
                                buttonType: 'checkout',
                                allowedPaymentMethods: vm.allowedPaymentMethods,
                                onClick: () => vm.handlePay(),
                            });

                            container.appendChild(button);
                            button.style.width = '100%';
                            button.style.height = '48px';
                            Judopay.loadLibrary();
                            vm.session = res.data;
                        }
                    })
                        .catch(function(err) {
                            console.error(err);
                        });
                });
        },
        handlePay: function() {
            const vm = this;

            if(!vm.session) return;

            vm.client.loadPaymentData(vm.session.config)
                .then(function(e) {
                    Judopay.judo.postMessage({
                        params: vm.payload,
                        messageType: 'SUBMIT_GOOGLE_PAY_REQUEST'
                    })
                        .then(function(res) {
                            Judopay.judo.digitalWallets.handleGooglePayResult({
                                paymentSession: vm.payload.paymentSession,
                                onError: function() {
                                    window.location.assign('/shop/checkout/judopay/process/?method=gpay');
                                },
                                onSuccess: function(payment) {
                                    $.post('/shop/checkout/judopay/payment/?method=gpay', payment)
                                        .always(function() {
                                            window.location.assign('/shop/checkout/judopay/process/?method=gpay');
                                        })
                                },
                            }, e, res)
                        })
                        .catch(function(e) {
                            console.error(e);
                        })
                }).catch(function(err){
                console.error(err);
            });
        }
    }
});
